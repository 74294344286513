<style>


    body {

        color: white;
        margin: 0;
        padding: 0;

    }

    .content {
        font-size: 14px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        min-height: 100vh;
        background: url("../../assets/answer_b.png") no-repeat center/cover fixed, linear-gradient(to top, #3BC0E1, #265599, #265599);
    }

    .line {
        display: flex;
    }

    .top1 {
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
        justify-content: center;
        padding: 15px 0;
        width: 100%;
    }

    .top2 {
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.65);
        font-size: 12px;
        width: 100%;
    }

    .top2-1 {
        color: white;
        font-size: 14px
    }

    .proLine {
        margin-top: 10px;
    }

    .con {
        background: white;
        color: #333333;
        border-radius: 6px;
        margin-top: 66px;
        padding: 20px 15px;
        font-size: 12px;
        width: 100%;
    }

    .con-type {
        background-color: #265599;
        color: white;
        width: 56px;
        padding: 5px 10px;
        border-radius: 3px;
    }

    .con-con {
        color: #333333;
        margin-top: 15px;
        font-size: 14px;
    }

    .con-opt {
        font-size: 14px;
    }

    .el-radio {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        display: flex;
        background-color: #F9F9F9;
        align-items: center;
        margin-top: 10px;
        padding: 14px 16px;
        width: 100%;
        overflow-wrap: break-word;
        border-radius: 6px;
    }

    .is-checked {
        border-radius: 6px;
        background-color: #EFF7FF;

    }

    .el-radio__label {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 18px;
        vertical-align: middle;
        display: inline-block;

    }

    /* 选中后的字体颜色 */
    .el-radio__input.is-checked + .el-radio__label {
        color: #265599 !important;
    }

    /* 选中后小圆点的颜色 */
    .el-radio__input.is-checked .el-radio__inner {
        background: #265599 !important;
        border-color: #265599 !important;
    }

    .el-checkbox {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        display: flex;
        background-color: #F9F9F9;
        align-items: center;
        margin-top: 10px;
        padding: 14px 16px;
        width: 100%;
        overflow-wrap: break-word;
        border-radius: 6px;

    }

    .is-checked {
        border-radius: 6px;
        background-color: #EFF7FF;

    }

    .el-checkbox__label {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 18px;
        vertical-align: middle;
        display: inline-block;

    }


    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: #516aab !important;
        background-color: #516aab !important;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #516aab !important;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #516aab !important;
    }


    .next {
        background: white;
        border-radius: 50px;
        color: #333333;
        padding: 12px 0;
        text-align: center;
        margin-top: 40px;
        width: 240px;
    }

    /* 当前状态颜色 */
    .progressBar >>> .el-progress-bar__outer {
        background-color: rgba(255, 255, 255, 0.2);
    }

    /* 进度条的背景色 */
    .progressBar >>> .el-progress-bar__inner {
        background-color: #fff;
    }


</style>
<template>

    <div class="content">
        <div class="top1 line">剩余时间：{{htime}}</div>
        <div style="width: 100%">
            <div class="line top2">
                <div>答题进度：<span class="top2-1">{{index+1}}</span>/{{examData.length}}</div>
                <!--<div>剩余时间：{{htime}}</div>-->
            </div>
            <div class="proLine">
                <el-progress type="line" :show-text="false" stroke-linecap="round" :stroke-width="8"
                             :percentage="pro" define-back-color="green" color="#12e01280"></el-progress>


            </div>
        </div>

        <div class="con">
            <div class="con-type" v-if="examData[index].type==1">
                单选题
            </div>
            <div class="con-type" v-if="examData[index].type==2">
                多选题
            </div>
            <div class="con-con">
                {{examData[index].title}}
            </div>
            <div class="con-opt" v-if="examData[index].type==1">
                <el-radio v-model="radio" label="A" v-if="examData[index].optionA">A、{{examData[index].optionA}}
                </el-radio>
                <el-radio v-model="radio" label="B" v-if="examData[index].optionB">B、{{examData[index].optionB}}
                </el-radio>
                <el-radio v-model="radio" label="C" v-if="examData[index].optionC">C、{{examData[index].optionC}}
                </el-radio>
                <el-radio v-model="radio" label="D" v-if="examData[index].optionD">D、{{examData[index].optionD}}
                </el-radio>
            </div>
            <div class="con-opt" v-if="examData[index].type==2">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox label="A" v-if="examData[index].optionA">A、{{examData[index].optionA}}
                    </el-checkbox>
                    <el-checkbox label="B" v-if="examData[index].optionB">B、{{examData[index].optionB}}
                    </el-checkbox>
                    <el-checkbox label="C" v-if="examData[index].optionC">C、{{examData[index].optionC}}
                    </el-checkbox>
                    <el-checkbox label="D" v-if="examData[index].optionD">D、{{examData[index].optionD}}
                    </el-checkbox>
                </el-checkbox-group>
            </div>

        </div>

        <div class="next" @click="nextQuestion">下一题</div>

    </div>
</template>
<script>
    import request from "../../plugins/axios.js";

    export default {
        data() {
            return {
                radio: "",
                examData: [{title: ''}],
                index: 0,
                pro: 0,
                checkList: [],
                htime: '',
                systemTime: '',
                suExam: false
            };
        },
        components: {},
        mounted() {
            let that = this
            //设置背景动画播放速度
            let u = localStorage.getItem('u')
            if (!u) {
                this.$router.push("/login");
            }

            var userAgent = navigator.userAgent.toLowerCase();
            // 判断是否在微信中打开
            if (userAgent.indexOf('micromessenger') !== -1) {
                that.wxShow = true;
            } else {
                that.$message.error('请在微信中打开');
                that.wxShow = false;
                return false
            }

            that.exam()
            that.getTime();

        },
        methods: {

            zdExam() {
                let that = this

                if (that.suExam == false) {
                    that.suExam = true
                    let loadding = that.$loading({
                        lock: true,
                        text: '考试时间到，系统自动提交中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });

                    let u = localStorage.getItem('u')
                    let examData = that.examData
                    request({
                        url: "/mn/e/uea",
                        data: {
                            exam: JSON.stringify(examData)
                        },
                    }).then((ret) => {
                        loadding.close()
                        if (ret.code == 200) {
                            that.$message.success(ret.message);
                            localStorage.clear()
                            localStorage.setItem('u', u)
                            this.$router.push("/home");
                        } else if (ret.code == 204) {
                            this.$router.push("/login");
                        } else {
                            that.$message.error(ret.message);
                        }
                    })
                }
            },
            getTime() {
                let that = this
                let hour = 3600;//到技术秒
                let u = localStorage.getItem('u')
               // let exami0=localStorage.getItem('')


                request({
                    url: "/mn/e/system_time",
                    data: {},
                }).then((ret) => {
                    let systemTime = ret.data
                    that.systemTime = systemTime
                    let exam_time = localStorage.getItem('exam_time')
                    let time = systemTime - exam_time

                    if (time > hour) {
                        //超过一小时直接交卷

                        that.zdExam()

                    } else {
                        time = hour - time
                        let miao = time % 60;
                        let fen = parseInt(time / 60)
                        that.countDown(fen, miao);
                    }
                })
            },
            countDown(minutes, seconds) {
                let that = this

                let exam_time = localStorage.getItem('exam_time');

                setInterval(function () {
                    if (seconds === 0) {
                        if (minutes === 0) {
                            clearInterval();
                            return;
                        } else {
                            minutes--;
                            seconds = 59;
                        }
                    } else {
                        seconds--;
                    }


                    if (minutes <= 0 && seconds <= 0) {

                        that.zdExam()
                    }
                    // 显示倒计时到页面上
                    if (seconds < 10) {
                        that.htime = minutes + ":0" + seconds
                    } else {
                        that.htime = minutes + ":" + seconds
                    }

                }, 1000);
            },
            exam() {
                let that = this
                let u = localStorage.getItem('u')
                let token = JSON.parse(u).token
                let lname = 'exam_data' + token;
                let examData = JSON.parse(localStorage.getItem(lname))

                if (!examData) {
                    this.$router.push("/home");
                }

                let index = that.index
                if (localStorage.getItem('exami0')) {
                    index = JSON.parse(localStorage.getItem('exami0'))
                    if (index >= examData.length - 1) {
                        index = examData.length - 1
                    }
                    that.index = index
                }
                that.examData = examData
                that.pro = (index + 1) / examData.length * 100
            }
            ,
            nextQuestion() {
                let that = this
                let examData = that.examData
                let index = that.index
                let type = examData[index].type
                let user_question_answer = '';
                if (type == 1) {
                    if (!that.radio) {
                        that.$message.error('请先选择答案');
                        return false
                    }
                    user_question_answer = that.radio
                }

                if (type == 2) {
                    if (that.checkList.length < 1) {
                        that.$message.error('请先选择答案');
                        return false
                    }
                    user_question_answer = that.checkList
                }


                let radio = that.radio
                let u = localStorage.getItem('u')
                let token = JSON.parse(u).token
                let group = localStorage.getItem('examg')
                let lname = 'exam_data' + token;


                localStorage.setItem('exami0', index)
                examData[index].user_answer = user_question_answer
                if (index + 1 == examData.length) {
                    //t
                    let loadding = that.$loading({
                        lock: true,
                        text: '提交中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });


                    request({
                        url: "/mn/e/uea",
                        data: {
                            exam: JSON.stringify(examData)
                        },
                    }).then((ret) => {
                        loadding.close()
                        if (ret.code == 200) {
                            that.$message.success(ret.message);
                            localStorage.clear()
                            localStorage.setItem('u', u)

                            this.$router.push("/home");
                        } else {

                            that.$message.error(ret.message);
                        }
                    })
                }

                that.getTime();
                localStorage.setItem(lname, JSON.stringify(examData))
                that.radio = ''
                that.index = index + 1
                that.checkList = []
                that.pro = (that.index + 1) / examData.length * 100
                localStorage.setItem('exami0', that.index)


            }
        },
    }
    ;
</script>
